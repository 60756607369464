import React from 'react';
import {CheckoutButton} from './CheckoutButton/CheckoutButton';
import {CashierExpressButtons} from '../CashierExpressButtons/CashierExpressButtons';
import {useControllerProps} from '../../ControllerContext';
import s from './CheckoutButtons.scss';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';

export enum CheckoutButtonsDataHook {
  Default = 'CheckoutButtons.default',
  Top = 'CheckoutButtons.topView',
}

interface CheckoutButtonsProps {
  isOnTop?: boolean;
}

export function CheckoutButtons(props: CheckoutButtonsProps) {
  const {shouldShowDynamicPaymentOptions, dynamicPaymentOptionsProps} = useControllerProps().cashierExpressStore;
  const {isCheckoutRegularFlowEnabled, shouldUseExpressCheckoutButtonsLibrary, cart} = useControllerProps().cartStore;
  const {isCheckoutButtonDisabled} = useControllerProps().navigationStore;
  const {isLoading} = useControllerProps();
  const {isSSR} = useEnvironment();
  const {isOnTop} = props;
  const {total} = cart.totals;
  const dataHook = isOnTop ? CheckoutButtonsDataHook.Top : CheckoutButtonsDataHook.Default;
  const shouldRequestShipping = dynamicPaymentOptionsProps?.shouldRequestShipping ?? false;
  const canCheckout = dynamicPaymentOptionsProps?.canCheckout ?? false;

  return (
    <div data-hook={dataHook} className={s.checkoutButtons}>
      {isCheckoutRegularFlowEnabled && <CheckoutButton disabled={isCheckoutButtonDisabled || isLoading} />}
      {shouldShowDynamicPaymentOptions && !isSSR && !shouldUseExpressCheckoutButtonsLibrary ? (
        <CashierExpressButtons />
      ) : null}
      {shouldShowDynamicPaymentOptions && !isSSR && shouldUseExpressCheckoutButtonsLibrary ? (
        <div className={s.expressButtonsContainer}>
          <ExpressCheckoutButtons
            layout={'vertical'}
            isShippable={shouldRequestShipping}
            estimatedTotal={total.toString()}
            canStartPayment={canCheckout}
            theme={dynamicPaymentOptionsProps?.getButtonTheme}
            buttonStyle={dynamicPaymentOptionsProps?.buttonStyle}
          />
        </div>
      ) : null}
    </div>
  );
}
